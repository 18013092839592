/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { React, useState, useEffect, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import axios from 'src/utils/axios';

import { useTranslate } from 'src/locales';
import { setForcrSession } from 'src/auth/context/jwt/utils';
import { MaintenanceIllustration } from 'src/assets/illustrations';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import PaymentSummary from './payment-summary';
import PaymentBillingAddress from './payment-billing-address';
import PaymentBillingKey from './payment-billing-key';
// ----------------------------------------------------------------------
InstallService.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default function InstallService({ title, subheader, ...other }) {
  const { t } = useTranslate();
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [Subscription, setSubscription] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [method, setMethod] = useState('');
  const [producto, setProducto] = useState([]);
  const [productoName, setProductoName] = useState('');
  const [license, setLicense] = useState('');
  const { guid } = useParams();

  const handleChangeMethod = useCallback((newValue, productsList) => {
    setMethod(newValue);
    setProducto(newValue);
    setProductoName(productsList);
  }, []);

  const handleCreateSession = async () => {
    await axios
      .get(`/api/stripe/create_checkout_session?key=${method.id}`, Request)
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((error) => {});
  };
  const handlePortalSession = async () => {
    await axios
      .get(`/api/stripe/create_portal_session`, Request)
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((error) => {});
  };
  const handlelistSubscriptions = async () => {
    await axios
      .get(`/api/stripe/list_subscriptions`, Request)
      .then((res) => {
        setSubscription(true);
        setSubscription1(res.data[0]);
      })
      .catch((error) => {
        setSubscription(false);
      });
  };
  const [subscription1, setSubscription1] = useState(null);
  const isSubscriptionCancelled = (subscription) =>
    subscription1?.canceled_at && subscription1?.cancel_at_period_end;
  const SuccessDisplay = () => (
    <Stack sx={{ alignItems: 'center' }}>
      <Label
        variant="filled"
        color={isSubscriptionCancelled(subscription1) ? 'error' : 'success'}
        sx={{
          position: 'relative',
          top: 110,
          zIndex: 99,
          left: 150,

          backgroundColor: isSubscriptionCancelled(subscription1) ? '#ffd1d9' : '#dcffd9',
          color: isSubscriptionCancelled(subscription1) ? '#941d1d' : '#518e2e',
        }}
      >
        {isSubscriptionCancelled(subscription1)
          ? t('listmedicine.inactive')
          : t('listmedicine.active')}
      </Label>

      <Typography variant="h3" sx={{ mb: 2 }}>
        {' '}
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}> </Typography>

      <MaintenanceIllustration sx={{ my: 10, height: 240 }} />

      <Button onClick={() => handlePortalSession()} size="large" variant="contained">
        {t('payments.manage')}
      </Button>
    </Stack>
  );
  useEffect(() => {}, []);

  const handleSubmitLoginToken = async () => {
    await axios
      .get(`/api/login/login_token`, Request)
      .then((res) => {
        setForcrSession(res.data.accessToken);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    handlelistSubscriptions();
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
      console.log('token reflesh');
      handleSubmitLoginToken();
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  useEffect(() => {
    console.log(guid);
    if (guid !== undefined) {
      setLicense(guid);
    }
  }, [guid]);

  return Subscription === null ? (
    <Container
      sx={{
        pt: 14,
        pb: 8,
        minHeight: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Dejar esta vista en blanco */}
    </Container>
  ) : Subscription === false ? (
    <Container
      sx={{
        pt: 14,
        pb: 8,
        minHeight: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        rowSpacing={{ xs: 5, md: 0 }}
        columnSpacing={{ xs: 0, md: 4 }}
        justifyContent="center"
      >
        <Grid item xs={12} md={8}>
          <Box
            gap={3}
            display="grid"
            sx={{
              p: { md: 5, xs: 3 },
              borderRadius: 2,
              border: (theme) => ({
                md: `dashed 0.5px ${theme.palette.divider}`,
                xs: `dashed 0.5px ${theme.palette.divider}`,
              }),
              maxWidth: '600px',
            }}
          >
            <PaymentBillingAddress selectedMethod={method} onChangeMethod={handleChangeMethod} />
            <PaymentBillingKey license={license} setLicense={setLicense} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <PaymentSummary
            producto={producto}
            products={productoName}
            onClick={handleCreateSession}
          />
        </Grid>
      </Grid>
    </Container>
  ) : (
    <SuccessDisplay sessionId={sessionId} />
  );
}
