import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, init = false, sx, ...other }, ref) => {
  //  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = '#384259';

  // const PRIMARY_DARK = '#F73859';

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 70,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4227.000000 673.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,673.000000) scale(0.100000,-0.100000)" stroke="none">
          <path
            fill="#384259"
            d="M17415 6719 c-635 -39 -1205 -215 -1723 -529 -766 -464 -1308 -1233
-1482 -2100 -55 -270 -64 -377 -65 -715 0 -315 6 -404 46 -630 156 -904 684
-1686 1467 -2177 474 -297 1003 -474 1612 -540 179 -20 712 -16 890 6 485 59
867 169 1250 361 360 180 632 377 906 657 652 664 967 1510 923 2485 -10 215
-22 327 -60 520 -234 1204 -1142 2167 -2377 2522 -418 120 -911 170 -1387 140z
m491 -1280 c532 -51 1012 -295 1332 -674 324 -385 484 -843 484 -1390 1 -381
-61 -670 -207 -973 -194 -402 -486 -702 -875 -897 -277 -139 -536 -202 -870
-212 -403 -12 -745 61 -1059 228 -560 298 -924 823 -1028 1480 -25 160 -25
577 0 734 74 463 265 844 578 1152 161 158 310 262 531 368 323 155 727 222
1114 184z"
          />
          <path
            fill="#384259"
            d="M25580 6723 c-123 -6 -365 -34 -510 -59 -680 -119 -1292 -419 -1793
-878 -295 -270 -584 -680 -748 -1061 -194 -450 -279 -911 -266 -1445 10 -411
66 -726 195 -1085 415 -1157 1487 -1981 2797 -2149 607 -78 1251 -24 1788 150
731 238 1353 698 1763 1304 344 509 519 1041 553 1683 54 996 -287 1886 -980
2563 -706 688 -1682 1029 -2799 977z m451 -1283 c922 -90 1621 -738 1779
-1650 59 -334 43 -712 -41 -1030 -106 -398 -330 -755 -634 -1009 -362 -301
-818 -461 -1319 -461 -960 0 -1728 580 -1966 1485 -59 228 -85 579 -61 830 63
652 384 1197 899 1529 104 68 315 168 440 209 120 40 315 82 443 96 120 12
339 13 460 1z"
          />
          <path
            fill="#384259"
            d="M32760 6719 c-389 -26 -740 -95 -1037 -204 -141 -52 -401 -182 -523
-262 -400 -263 -663 -620 -780 -1058 -43 -160 -60 -296 -60 -490 0 -247 22
-402 85 -591 73 -219 167 -367 349 -550 256 -256 479 -387 899 -529 241 -80
582 -177 926 -260 776 -189 987 -255 1175 -367 239 -143 336 -286 336 -497 0
-187 -70 -328 -224 -448 -194 -151 -439 -221 -853 -243 -750 -39 -1573 182
-2198 591 -66 43 -120 77 -121 76 -6 -7 -504 -1118 -504 -1125 0 -16 247 -172
391 -246 1044 -539 2609 -665 3626 -291 128 47 375 167 483 235 491 307 794
754 876 1295 18 119 20 400 4 530 -21 173 -77 370 -142 500 -114 227 -358 478
-631 647 -284 177 -794 345 -1677 553 -419 99 -712 187 -917 278 -272 119
-393 281 -393 522 1 370 284 622 800 711 128 22 566 31 726 15 483 -48 954
-198 1427 -452 43 -23 79 -40 81 -38 8 8 456 1117 456 1127 0 14 -98 71 -257
151 -417 208 -939 349 -1503 406 -189 19 -636 27 -820 14z"
          />
          <path
            fill="#384259"
            d="M6530 3359 l0 -3260 1648 4 c1146 3 1677 8 1747 16 478 54 794 125
1115 248 460 177 816 401 1138 717 498 488 779 1069 879 1816 25 189 25 735 0
925 -52 389 -143 697 -297 1010 -250 506 -614 907 -1105 1217 -147 92 -458
245 -625 307 -378 139 -786 223 -1230 251 -102 6 -756 10 -1717 10 l-1553 0 0
-3261z m3280 1997 c515 -76 894 -256 1205 -570 139 -141 226 -263 315 -441
149 -299 210 -583 210 -982 0 -587 -163 -1036 -508 -1394 -319 -333 -711 -519
-1253 -596 -95 -13 -251 -16 -926 -20 l-813 -5 0 2017 0 2016 828 -4 c713 -3
843 -6 942 -21z"
          />
          <path
            fill="#384259"
            d="M37240 3370 l0 -3250 2515 0 2515 0 0 605 0 605 -1770 0 -1770 0 0
750 0 750 1510 0 1510 0 0 585 0 585 -1510 0 -1510 0 0 705 0 705 1710 0 1710
0 0 605 0 605 -2455 0 -2455 0 0 -3250z"
          />
          <path
            fill="#F73859"
            d="M2835 6390 c-493 -51 -886 -162 -1276 -361 -724 -369 -1236 -997
-1438 -1759 -70 -264 -100 -486 -108 -795 -21 -793 183 -1442 628 -1995 332
-413 820 -743 1364 -925 331 -111 645 -166 1045 -186 154 -7 2977 -40 2986
-35 1 1 13 1254 27 2786 15 1532 29 2891 32 3021 l6 236 -598 6 c-1102 13
-2576 16 -2668 7z m1355 -3035 l0 -2015 -530 0 -530 0 0 2008 c0 1105 3 2012
7 2015 3 4 242 7 530 7 l523 0 0 -2015z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }
  if (init) {
    <Link component={RouterLink} href="dashboard" sx={{ display: 'contents' }}>
      {logo}
    </Link>;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  init: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
