import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';
import { useTranslate } from 'src/locales';
import { isValidToken } from 'src/auth/context/jwt/utils';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { HEADER } from '../config-layout';
import LoginButton from '../common/login-button';
import HeaderShadow from '../common/header-shadow';
import LanguagePopover from '../common/language-popover';
// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { t } = useTranslate();
  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const navConfig = [
    {
      title: t('pages.inicio'),
      icon: <Iconify icon="solar:home-2-bold-duotone" />,
      path: '/',
    },
    {
      title: t('pages.robot'),
      icon: <Iconify icon="solar:home-2-bold-duotone" />,
      path: '/robots',
    },
    {
      title: 'Blog',
      icon: <Iconify icon="solar:home-2-bold-duotone" />,
      path: 'https://www.idoose.com/blog/',
    },

    {
      title: t('pages.pages'),
      path: '/pages',
      icon: <Iconify icon="solar:file-bold-duotone" />,
      children: [
        {
          subheader: t('pages.info'),
          items: [
            { title: t('pages.about'), path: paths.about },
            { title: t('pages.contact'), path: paths.contact },
          ],
        },
      ],
    },
  ];

  const BLogin = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const isAuthenticated = accessToken && isValidToken(accessToken);

    if (isAuthenticated) {
      return (
        <IconButton
          onClick={() => {
            window.location.href = paths.auth.login;
          }}
        >
          <Iconify icon="uis:lock" width={24} />
        </IconButton>
      );
    }
    return (
      <IconButton
        onClick={() => {
          window.location.href = paths.auth.login;
        }}
      >
        <Iconify icon="uis:lock" width={24} />
      </IconButton>
    );
  };

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />

          <Box sx={{ flexGrow: 1 }} />
          {mdUp && <NavDesktop data={navConfig} />}
          <Stack alignItems="center" spacing={1} direction={{ xs: 'row', md: 'row-reverse' }}>
            {!mdUp && <BLogin />}
            <LanguagePopover
              sx={{
                ml: { xs: 1, md: 0 },
                mr: { md: 2 },
              }}
            />

            {mdUp && <LoginButton />}

            {!mdUp && <NavMobile data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
