import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslate } from 'src/locales';
import { GuestGuard } from 'src/auth/guard';
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
import AuthModernLayout from 'src/layouts/auth/modern';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

import { SplashScreen } from 'src/components/loading-screen';

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const ModernNewPasswordPage = lazy(() => import('src/pages/auth-demo/modern/new-password'));
const ForgotPasswordModernPage = lazy(() => import('src/pages/auth-demo/modern/forgot-password'));
const VerifyModernPage = lazy(() => import('src/pages/auth-demo/modern/verify'));
// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <JwtLoginPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <AuthModernCompactLayout >
            <JwtRegisterPage />
          </AuthModernCompactLayout>
        ),
      },
      {
        element: (
          <AuthModernCompactLayout>
            <Outlet />
          </AuthModernCompactLayout>
        ),
        children: [
          { path: 'forgot-password', element: <ForgotPasswordModernPage /> },
          {
            path: 'new-password',
            children: [
              {
                path: ':guid',
                element: <ModernNewPasswordPage />,
              },
            ],
          },
          {
            path: 'validation',
            children: [
              {
                path: ':guid',
                element: <VerifyModernPage />,
              },
            ],
          },
          { path: 'verify', element: <VerifyModernPage /> },
        ],
      },
    ],
  },
];
