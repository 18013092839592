/* eslint-disable react/no-unstable-nested-components */
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';

import { useOffSetTop } from 'src/hooks/use-off-set-top';

import { bgBlur } from 'src/theme/css';
import { ExitSession, isValidToken } from 'src/auth/context/jwt/utils';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

import { HEADER } from '../config-layout';
import HeaderShadow from './header-shadow';
import LanguagePopover from './language-popover';
// ----------------------------------------------------------------------

export default function HeaderSimple() {
  const theme = useTheme();

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const path = window.location.pathname;
  const searchParams = window.location.search;

  // Construir la URL completa sin el dominio
  const fullUrlWithoutDomain = encodeURIComponent(path + searchParams);

  const BLogin = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    const isAuthenticated = accessToken && isValidToken(accessToken);

    const handleClick = () => {
      if (isAuthenticated) {
        ExitSession();
        window.location.reload();
      } else {
        window.location.href = `${paths.auth.login}?returnTo=${fullUrlWithoutDomain}`;
      }
    };

    const icon = isAuthenticated ? 'majesticons:door-exit' : 'uis:lock';

    return (
      <IconButton onClick={handleClick}>
        <Iconify icon={icon} width={24} />
      </IconButton>
    );
  };

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Logo />

        <Stack direction="row" alignItems="center" spacing={1}>
          <BLogin />

          <LanguagePopover />
        </Stack>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
