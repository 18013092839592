import { jwtDecode } from 'src/auth/context/jwt/utils';

export default function Permision(id) {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const posHex = Math.floor((id - 1) / 8) * 2;
  const hex = decoded.permission.substr(posHex, 2);
  const intValue = parseInt(hex, 16);
  let num_ = id - 1;
  while (num_ >= 8) {
    num_ -= 8;
  }
  const set_ = getBit(intValue, num_);
  return set_;
}



function getBit(byteValue, bitPosition) {
  // eslint-disable-next-line no-bitwise, eqeqeq
  return (byteValue & (1 << bitPosition)) != 0;
}
