import Pusher from 'pusher-js';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { PUSHER_KEY, PUSHER_CLUSTER } from 'src/config-global';
import { jwtDecode, isValidToken, InstallationID, CenterID } from 'src/auth/context/jwt/utils';
// ----------------------------------------------------------------------

const PUSHER_KEY_L = PUSHER_KEY;
const PUSHER_CLUSTER_L = PUSHER_CLUSTER;

export const PusherC = new Pusher(PUSHER_KEY_L, {
  cluster: PUSHER_CLUSTER_L,
});

export const PusherCore = () => PusherC;

export const PusherController = (MsgShow) => {
  const channel1 = PusherC.subscribe('general');
  channel1.bind('notices', (data) => {
    MsgShow(data);
  });
};

export const SubscribeNotice = (MsgShow) => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken && isValidToken(accessToken)) {
    const decoded = jwtDecode(accessToken);
    const installation = InstallationID();

    console.log(installation);
    Cookies.set('IdooseCookie', true, { expires: 9999 });

    const channel1 = PusherC.subscribe('notices');
    const channel2 = PusherC.subscribe(`notices_u_${decoded.id_user}`);
    const channel3 = PusherC.subscribe(`notices_c_${decoded.center_login}`);
    const channel4 = PusherC.subscribe(`notices_i_${installation}`);

    channel1.unbind('general');
    channel2.unbind(`general`);
    channel3.unbind(`general`);
    channel4.unbind(`general`);

    channel1.bind('general', (data) => {
      MsgShow(data);
    });
    channel2.bind(`general`, (data) => {
      MsgShow(data);
    });
    channel3.bind(`general`, (data) => {
      MsgShow(data);
    });
    channel4.bind(`general`, (data) => {
      MsgShow(data);
    });
  }
};

export const SubscribeBob = (MsgShow) => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken && isValidToken(accessToken)) {
    const installation = InstallationID();
    const channel1 = PusherC.subscribe(`bob_i_${installation}_r`);
    channel1.unbind('bob');
    channel1.bind('bob', (data) => {
      MsgShow(data);
    });
  }
};

export const SubscribeSync = (MsgShow) => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken && isValidToken(accessToken)) {
    const installation = CenterID();
    const channel1 = PusherC.subscribe(`sync_c_${installation}_r`);
    channel1.unbind('sync');
    channel1.bind('sync', (data) => {
      MsgShow(data);
    });
  }
};