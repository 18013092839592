/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
import { Helmet } from 'react-helmet-async';

import { GOOGLEANALYTICS_ID } from 'src/config-global';
// ----------------------------------------------------------------------

const GA_MEASUREMENT_ID = GOOGLEANALYTICS_ID;

export default function GoogleAnalytics() {
  const cookies = document.cookie.split(';');

  // Variable para verificar si la cookie IdooseCookie existe
  let cookieExists = false;

  // Iterar sobre cada cookie
  for (let i = 0; i < cookies.length; i++) {
    // Separar el nombre y el valor de la cookie
    const cookieParts = cookies[i].split('=');
    const cookieName = cookieParts[0].trim();
    const cookieValue = cookieParts[1] ? cookieParts[1].trim() : '';

    // Verificar si el nombre de la cookie es "IdooseCookie"
    if (cookieName === 'IdooseCookie') {
      cookieExists = true; // Marcar que la cookie existe
      // Verificar el valor de la cookie
      if (cookieValue === 'true') {
        // Mostrar una alerta si la cookie está establecida en true
        return (
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
            />
            <script>
              {`
                      window.dataLayer = window.dataLayer || [];
            
                      function gtag() {
                        dataLayer.push(arguments);
                      }
            
                      gtag('js', new Date());
                      gtag('config', '${GA_MEASUREMENT_ID}');
                    `}
            </script>
          </Helmet>
        );
      } else if (cookieValue === 'false') {
        // Mostrar una alerta si la cookie está establecida en false

        const cookies1 = document.cookie.split(';');
        // eslint-disable-next-line no-plusplus
        for (let i1 = 0; i1 < cookies1.length; i1++) {
          const cookie = cookies1[i1];
          const eqPos = cookie.indexOf('=');
          const nombre = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

          if (String(nombre).trim() !== String('IdooseCookie').trim()) {
            document.cookie = `${nombre}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
          }
        }
      } else {
        // Mostrar una alerta si el valor de la cookie es diferente de true o false
      }
      break; // Terminar el bucle si se encuentra la cookie
    }
  }

  // Si la cookie no existe, mostrar una alerta
  if (!cookieExists) {
    return (
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
  
            function gtag() {
              dataLayer.push(arguments);
            }
  
            gtag('js', new Date());
            gtag('config', '${GA_MEASUREMENT_ID}');
          `}
        </script>
      </Helmet>
    );
  }
}
