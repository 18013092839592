import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const LogoMini = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = '#384259';

  const PRIMARY_DARK = '#F73859';

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 64,
        height: 64,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="447.926 369.539 322.904 171.705"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g>
            <g>
              <path
                fill={PRIMARY_DARK}
                d="M529.99,528.03c-15.33-0.15-28.81-3.29-40.45-9.42s-20.7-14.65-27.18-25.57c-6.48-10.91-9.65-23.7-9.5-38.36 c0.15-14.65,3.57-27.37,10.26-38.16c6.7-10.79,15.92-19.13,27.68-25.03c11.76-5.9,25.3-8.77,40.63-8.62l67.67,0.67l-1.44,145.16 L529.99,528.03z"
              />
              <path fill="#FFFFFF" d="M528.02,504.46v-96.59h25.18v96.59H528.02z" />
              <path d="M609.45,377.83h71.02c16.97,0,31.97,3.24,45,9.72c13.03,6.48,23.15,15.6,30.37,27.36c7.22,11.76,10.83,25.46,10.83,41.09 c0,15.63-3.61,29.33-10.83,41.09c-7.22,11.76-17.35,20.88-30.37,27.36c-13.03,6.48-28.03,9.71-45,9.71h-71.02V377.83z M678.68,504.46c15.63,0,28.1-4.35,37.41-13.06c9.3-8.71,13.96-20.51,13.96-35.4c0-14.89-4.66-26.69-13.96-35.4 c-9.31-8.71-21.78-13.06-37.41-13.06h-33.05v96.93H678.68z" />
            </g>
            <g>
              <g>
                <path
                  fill={PRIMARY_MAIN}
                  d="M609.42,377.83h70.84c16.93,0,31.89,3.23,44.89,9.69c12.99,6.46,23.09,15.56,30.3,27.29 c7.2,11.73,10.8,25.4,10.8,40.99s-3.6,29.26-10.8,40.99c-7.2,11.73-17.3,20.83-30.3,27.29c-13,6.46-27.96,9.69-44.89,9.69 h-70.84V377.83z M678.48,504.15c15.59,0,28.03-4.34,37.32-13.03c9.28-8.69,13.92-20.46,13.92-35.31 c0-14.85-4.64-26.62-13.92-35.31c-9.28-8.69-21.72-13.03-37.32-13.03h-32.97v96.68H678.48z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

LogoMini.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default LogoMini;
