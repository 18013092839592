// eslint-disable-next-line import/no-extraneous-dependencies
import { memo, useState } from 'react';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { CenterName, InstallationName } from 'src/auth/context/jwt/utils';

import SvgColor from 'src/components/svg-color';

import InstallPopover from '../Install-popover';
// ----------------------------------------------------------------------

function Searchbar() {
  const theme = useTheme();

  const [openDialog, setOpenDialog] = useState(false);

  const renderButton = (
    <Stack direction="row" alignItems="center" spacing={1}>
      <IconButton onClick={() => setOpenDialog(true)}>
        <SvgColor
          src="/assets/icons/navbar/ic_pharmacy.svg"
          sx={{
            width: 30,
            height: 30,

            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 1),
            },
          }}
        />
      </IconButton>

      <Stack>
        <Typography variant="h6">{InstallationName()}</Typography>
        <Typography variant="h7">{CenterName()}</Typography>
      </Stack>
    </Stack>
  );

  return (
    <>
      {renderButton}

      <InstallPopover openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </>
  );
}

export default memo(Searchbar);
