import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Scrollbars } from 'react-custom-scrollbars-2';
import InfiniteLoader from 'react-window-infinite-loader';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';

import axios from 'src/utils/axios';

import { useTranslate } from 'src/locales';
import { CenterID, setForcrSession } from 'src/auth/context/jwt/utils';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import ResultItem from './searchbar/result-item';
// ----------------------------------------------------------------------
CenterPopover.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
};
export default function CenterPopover({ openDialog, setOpenDialog, ...other }) {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filter_, setFilter] = useState('');
  const [Providers, setProviders] = useState([]);
  const [InstallID, setInstallID] = useState(-1);
  const [openDialogC, setOpenDialogC] = useState(false);
  const { t } = useTranslate();
  useEffect(() => {
    if (openDialog === true) {
      setSelectedIndex(CenterID);
      setProviders([]);
      setInstallID(-1);
    } else {
      setFilter('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleSubmitCenter = async (filter, scroll = false, skip = 0) => {
    setFilter(filter);
    if (scroll === false) {
      setProviders([]);
    }
    await axios
      .get(`/api/center/center_list?status=1&filter=${filter}&skip=${skip}`, Request)
      .then((res) => {
        if (scroll === true) {
          const newArr = [...Providers, ...res.data];
          setProviders(newArr);
        } else {
          setProviders(res.data);
        }
      })
      .catch((e) => {});
  };

  const ChangePharma = async (e) => {
    await handleSubmitChangeCenter(InstallID);
    setOpenDialogC(false);
    window.location.reload(false);
  };

  const handleSubmitChangeCenter = async (id) => {
    await axios
      .get(`/api/center/center_change?center=${id}`, Request)
      .then((res) => {
        setForcrSession(res.data.accessToken);
      })
      .catch((error) => {});
  };

  // eslint-disable-next-line no-undef
  const getItemSize = useMemo(
    () =>
      // Memoiza la función getItemSize para evitar recalculos innecesarios
      (index) =>
        // Ajusta este valor según la altura de tus elementos
        58,

    []
  );
  // eslint-disable-next-line no-undef
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const isItemLoaded = (index) => index < Providers.length;

  const itemCount = hasMoreItems ? Providers.length + 1 : Providers.length;
  const handleOpenDialog = (e) => {
    setInstallID(e);
    setOpenDialogC(true);
  };

  // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
  const Row = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return null;
    }

    const row = Providers[index];

    return (
      <List key={index} style={style} disablePadding>
        <ResultItem
          Key={selectedIndex}
          Id={row.id}
          path={`${row.city}, ${row.address}`}
          title={row.name}
          onClickItem={() => handleOpenDialog(row.id)}
        />
      </List>
    );
  };
  function handleOnChange(e) {
    if (e.key === 'Enter') {
      handleSubmitCenter(filter_);
    }
  }
  const handleChangeSeriesData = (event) => {
    setFilter(event.target.value);
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Búsqueda..."
            value={filter_}
            onChange={(e) => handleChangeSeriesData(e)}
            onKeyDown={(e) => handleOnChange(e)}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={(startIndex, stopIndex) => {
            handleSubmitCenter(filter_, true, startIndex);
          }}
        >
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              ref={ref}
              height={500}
              style={{ overflow: 'hidden' }}
              itemCount={itemCount}
              itemSize={getItemSize} // Ajusta este valor según la altura de tus elementos
              width="100%"
              onItemsRendered={onItemsRendered}
              outerElementType={Scrollbars}
            >
              {Row}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      </Dialog>
      <Dialog open={openDialogC} onClose={() => setOpenDialogC(false)}>
        <Stack spacing={1}>
          <DialogTitle>{t('centers.change')}</DialogTitle>

          <DialogActions>
            <Button
              autoFocus
              color="error"
              variant="contained"
              onClick={() => setOpenDialogC(false)}
            >
              {t('resetpass.back')}
            </Button>
            <Button onClick={ChangePharma} variant="contained" autoFocus>
              {t('centers.acept')}
            </Button>
          </DialogActions>
        </Stack>
      </Dialog>
    </>
  );
}
