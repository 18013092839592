import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import axios from 'src/utils/axios';

import { useTranslate } from 'src/locales';
import { setForcrSession } from 'src/auth/context/jwt/utils';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
// ----------------------------------------------------------------------
PaymentBillingKey.propTypes = {
  license: PropTypes.string.isRequired,
  setLicense: PropTypes.func.isRequired,
};

export default function PaymentBillingKey({ license, setLicense }) {
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const handleValidateSerial = async (serial) => {
    await axios
      .post(`/api/license/validate_serial_consumables?serial=${serial}`, Request)
      .then((res) => {
        console.log(res.data);
        enqueueSnackbar(`${t('posology.addsuccess')} ${res.data} ${t('various.units')}`, {
          variant: 'success',
        });
        handleSubmitLoginToken();
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
    setLicense('');
  };
  const handleSubmitLoginToken = async () => {
    await axios
      .get(`/api/login/login_token`, Request)
      .then((res) => {
        setForcrSession(res.data.accessToken);
      })
      .catch((error) => {});
  };
  useEffect(() => {}, []);

  const applyLicenseMask = (input) => {
    const numberOfGroups = 6;
    const charactersPerGroup = 5;
    const groupSeparator = '-';
    const encodingChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    // Filtrar los caracteres permitidos
    const filteredInput = input
      .toUpperCase()
      .split('')
      .filter((c) => encodingChars.includes(c))
      .join('');

    let result = '';
    for (let i = 0; i < filteredInput.length; i += charactersPerGroup) {
      if (i > 0) result += groupSeparator;
      result += filteredInput.substring(i, i + charactersPerGroup);
    }

    const maxLength =
      numberOfGroups * charactersPerGroup + (numberOfGroups - 1) * groupSeparator.length;
    return result.length > maxLength ? result.substring(0, maxLength) : result;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const maskedValue = applyLicenseMask(inputValue);
    setLicense(maskedValue);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        maxWidth: '800px',
      }}
    >
      <Typography variant="h6">{t('home.licence')}</Typography>
      <Stack spacing={1}>
        <TextField
          fullWidth
          value={license}
          onChange={handleChange}
          label={t('production.code')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="fluent:lock-closed-key-16-filled" width={24} />
              </InputAdornment>
            ),
          }}
        />
        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={() => handleValidateSerial(license)}
          sx={{ mt: 2, mb: 3 }}
          disabled={license.length < 35}
        >
          {t('register.create')}
        </Button>
      </Stack>
    </Stack>
  );
}
