import PropTypes from 'prop-types';
import  { useState, useEffect } from 'react';
import { useTranslate } from 'src/locales';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import Iconify from 'src/components/iconify';

import axios from 'src/utils/axios';
// ----------------------------------------------------------------------

export default function PaymentBillingAddress({ selectedMethod, onChangeMethod }) {

  const [products,setProducts] = useState([]);
  const [productsPrice,setProductsPrice] = useState([]);
  const { t } = useTranslate();

  const handleProductsPrice = async () => {
    await axios
      .get(`/api/stripe/prices_service`, Request)
      .then((res) => {
        setProductsPrice(res.data);
      })
      .catch((error) => {});
  };
  const handleProducts = async () => {
    await axios
      .get(`/api/stripe/product_service`, Request)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    handleProducts();
    handleProductsPrice();
  },[]);

  return (
    <Stack spacing={2}>
        <Typography variant="h6">{t('payments.subscription')}</Typography>
        <Stack spacing={2}>
        {Array.isArray(products) && products.length > 0 ? (
            productsPrice.map((option) => (
                <OptionItem
                    key={option.id}
                    option={option}
                    selected={selectedMethod === option}
                    onClick={() => onChangeMethod(option, products.find(product => product.id === option.product))}
                    products={products} 
                />
            ))
        ) : (
            <Typography>No products available</Typography>
        )}
        </Stack>
      </Stack>
  );
}
PaymentBillingAddress.propTypes = {
  selectedMethod: PropTypes.string.isRequired,
  onChangeMethod: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

function OptionItem({ option, selected, products, ...other }) {
  const objName = products.find(product => product.id === option.product);
    
  return (
    <Paper
      variant="outlined"
      key={option.id}
      sx={{
        p: 2.5,
        cursor: 'pointer',
        ...(selected && {
          boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
        }),
      }}
      {...other}
    >
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center">
            <Iconify
              icon={selected ? 'eva:checkmark-circle-2-fill' : 'eva:radio-button-off-fill'}
              width={24}
              sx={{
                mr: 2,
                color: selected ? 'primary.main' : 'text.secondary',
              }}
            />
            <Box component="span" sx={{ flexGrow: 1 }}>
              {objName.name}
            </Box>
            <Box component="span" sx={{ alignItems: 'flex-end'}}>
            {option.unit_amount / 100} €
            </Box>
          </Stack>
        }
        primaryTypographyProps={{ typography: 'subtitle2' }}
      />
    </Paper>
  );
}
OptionItem.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.bool,
  products:  PropTypes.array,
};


