import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import AuthModernCompactLayout2 from 'src/layouts/auth/modern-compact2';
import { SplashScreen } from 'src/components/loading-screen';

// JWT

const TakePage = lazy(() => import('src/pages/pulics/take'));
const InfoPage = lazy(() => import('src/pages/pulics/info'));
const ContactSuccedPage = lazy(() => import('src/pages/pulics/contact-succed'));
const RobotsPage = lazy(() => import('src/pages/robots'));
const TestPage = lazy(() => import('src/pages/testps'));
// ----------------------------------------------------------------------

export const Publics = [
  // Display
  {
    path: 'v',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        element: (
          <AuthModernCompactLayout2>
            <TakePage />
          </AuthModernCompactLayout2>
        ),
        index: true,
      },
      {
        path: ':guid',
        element: (
          <AuthModernCompactLayout2>
            <TakePage />
          </AuthModernCompactLayout2>
        ),
      },
    ],
  },
  // Display Info production
  {
    path: 'p',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        element: (
            <InfoPage />
        ),
        index: true,
      },
      {
        path: ':guid/:otherParam',
        element: (
            <InfoPage />
        ),
      },
      {
        path: ':guid',
        element: (
            <InfoPage />
        ),
      },
    ],
  },
  {
    path: 'robots',
    element: (
      <MainLayout>
        <RobotsPage />
      </MainLayout>
    ),
  },
  {
    path: 'contact-succed',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        element: (
          <AuthModernCompactLayout2>
            <ContactSuccedPage />
          </AuthModernCompactLayout2>
        ),
        index: true,
      },
    ],
  },
];
