// eslint-disable-next-line import/no-extraneous-dependencies
import CookieConsent from 'react-cookie-consent';
import { useTranslate } from 'src/locales';

export default function CookieConsentObj() {
  const { t } = useTranslate();

  const handleDecline = () => {
    const cookies = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const nombre = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (String(nombre).trim() !== String('IdooseCookie').trim()) {
        document.cookie = `${nombre}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }
    }
  };

  return (
    <CookieConsent
      enableDeclineButton
      location="bottom"
      buttonText={t('cookie_consent.acept')}
      declineButtonText={t('cookie_consent.reject')}
      cookieName="IdooseCookie"
      style={{ background: '#384259' }}
      buttonStyle={{ color: '#ffffff', fontSize: '13px', background: '#7AC7C4' }}
      expires={9999}
      onDecline={handleDecline}
    >
      <span style={{ fontSize: '15px' }}>{t('cookie_consent.message')}</span>
    </CookieConsent>
  );
}
